/********** Mobilesidebar ***********/
/***********************************/
.mobile-sidebar {
  display: none;
}
@media only screen and (max-width: 767px) {
  .mobile-sidebar {
    display: block;
  }
}
.mobile_icons-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
}
.mobile__nav {
  position: relative;
  z-index: 99;
}
.hamburger {
  cursor: pointer;
  z-index: 999;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: column;
  width: 27px;
  gap: 6px;
}
.hamburger.active span {
  background-color: white;
}
.hamburger .ham-container span {
  width: 27px;
  height: 2px;
  display: block;
  background-color: white;
  transition: all 0.3s ease-in-out;
}
.ham-container:first-child{
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.hamburger .ham-container:first-child span {
  width: 19px;
  justify-self: flex-end;
}
.hamburger .ham-container:nth-child(2) span {
  width: 27px;
}
.hamburger .ham-container:nth-child(3) span {
  width: 27px;
}

.navContents {
  width: 100%;
  height: 100vh;
  background: rgba(13, 12, 12, 0.98);
  backdrop-filter: blur(8px);
  position: fixed;
  z-index: 99;
  transition: all 0.3s ease-out;
  color: #fff;
  display: block;
  top: 0;
  right: -100%;
  visibility: hidden;
  opacity: 1;
}

.navContents__texts {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 0 32px;
  gap: 24px;
  position: relative;
  top: 130px;
  text-transform: capitalize;
}
.navContents__texts p {
  font-size: 24px;
}
.mobile__nav.active .hamburger .ham-container:nth-child(1) span {
  width: 15px;
  transform: translateY(3px) translateX(-7px) rotate(-45deg);
}

.mobile__nav.active .hamburger .ham-container:nth-child(2) span {
  width: 15px;
  transform: translateY(0px) translateX(-7px) rotate(45deg);
}

.mobile__nav.active .hamburger .ham-container:nth-child(3) span {
  opacity: 0;
}

.mobile__nav.active .hamburger {
  gap: 0;
  height: 30px;
  width: 30px;
  border: 1px solid #f6f6f6;
  justify-content: center;
  align-content: center;
  border-radius: 50%;
}
.mobile__nav.active .hamburger .ham-container {
  position: relative;
  left: 0.4px;
}
.mobile__nav.active .ham-container span {
  background-color: #6a6969;
}

.active .navContents {
  visibility: visible;
  right: 0;
}
/* dropdown */
.mobile__dropdown {
  width: 100%;
}
.navContents__texts .tab-list {
  cursor: pointer;
  transition: all 0.3s ease;
}
.navContents__texts .tab-list:hover {
  text-decoration: underline dashed #a3c9ee 1px;
}
.navContents__texts__dropdown {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.right-arrow--active {
  transform: rotate(90deg);
}
.dropdown-content {
  padding: 20px 20px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 15px;
  flex-direction: column;
}
.dropdown-content .heading {
  font-size: 12px;
  color: #7e7e7e;
}
.dropdown-content p,
.dropdown-list {
  color: #ededed;
  font-size: 20px;
  font-weight: 400;
  text-transform: capitalize;
}

.dropdown-list {
  transition: all 0.6s ease;
  cursor: pointer;
}
.dropdown-list:hover {
  text-decoration: underline white 1px;
}

@media only screen and (max-width: 767px) {

    body,
    html {
        font-size: 14px;
    }
}

@media only screen and (max-width: 340px){

    body,
    html {
        font-size: 13px;
        overflow-x: hidden;
    }
}
:root {
  --primaryColor: #000000;
  --blackColor: #000000;
  --successColor: #48d38a;
  --backgroundColor: #000000;
  --whiteColor: #ffffff;
  --textColor: rgba(255, 255, 255, 0.4);
  --lightTextColor: rgba(255, 255, 255, 0.3);
  --greyColor: rgba(255, 255, 255, 0.6);
  --blueColor: #0f71e2;
  --inputBackground: #131313;
  --overlay: rgba(0, 0, 0, 0.35);
  --buttonSecondary: #151515;
  --buttonDisabled: #073973;
}

.bg-dark {
  background-color: var(--backgroundColor);
}

.color-text {
  color: var(--whiteColor);
  opacity: 0.5;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.column--100 {
  width: 100% !important;
}

.column--90 {
  width: 90%;
}

.column--80 {
  width: 80%;
}

.column--50 {
  width: 50%;
}

.column--33 {
  width: 33.33333%;
}

/* Text font sizing */

h1 {
  font-size: 2.375rem;
  line-height: 1.2;
}

h2 {
  font-size: 2rem;
  line-height: 1.4;
}

h3 {
  font-size: 1.8rem;
  line-height: 1.5;
}

h4 {
  font-size: 1.42rem;
  line-height: 1.5;
}

h5 {
  font-size: 1.32rem;
  line-height: 1.5;
}

h6 {
  font-size: 1.2rem;
  line-height: 1.5;
}

p {
  font-size: 1rem;
  line-height: 1.5;
}

.text-lg {
  font-size: 2.5rem;
  line-height: 1.38;
}

.text-md {
  font-size: 2.15rem;
  line-height: 1.4;
}

.text-smd {
  font-size: 0.9375rem;
}

.text-sm {
  font-size: 0.8125rem;
}

.text-xlxs {
  font-size: 0.75rem;
}

.text-xs {
  font-size: 0.64rem;
}

.text-subheading {
  font-size: 1.13rem;
  line-height: 1.5;
}

.text-subheading-md {
  font-size: 1.25rem;
  line-height: 1.35;
}

.text-subheading-sm {
  font-size: 1.05rem;
  line-height: 1.35;
}

.page-heading-title {
  color: var(--primaryColor);
  font-size: 28px;
  font-weight: 800;
}

.text-normal {
  font-weight: 400;
}

.form-loading {
  position: relative;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  opacity: 0.8;
  pointer-events: none;
}

.form-loading span {
  opacity: 0;
  visibility: hidden;
}

.form-loading:after {
  position: absolute;
  content: "";
  width: 30px;
  height: 30px;
  display: inline-block;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiBzdHlsZT0ibWFyZ2luOiBhdXRvOyBiYWNrZ3JvdW5kOiBub25lOyBkaXNwbGF5OiBibG9jazsgc2hhcGUtcmVuZGVyaW5nOiBhdXRvOyIgd2lkdGg9IjIwMHB4IiBoZWlnaHQ9IjIwMHB4IiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgcHJlc2VydmVBc3BlY3RSYXRpbz0ieE1pZFlNaWQiPgo8Y2lyY2xlIGN4PSI1MCIgY3k9IjUwIiByPSIzMiIgc3Ryb2tlLXdpZHRoPSI4IiBzdHJva2U9IiNmZmZmZmYiIHN0cm9rZS1kYXNoYXJyYXk9IjUwLjI2NTQ4MjQ1NzQzNjY5IDUwLjI2NTQ4MjQ1NzQzNjY5IiBmaWxsPSJub25lIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHRyYW5zZm9ybT0icm90YXRlKDMxMS45NjcgNTAgNTApIj4KICA8YW5pbWF0ZVRyYW5zZm9ybSBhdHRyaWJ1dGVOYW1lPSJ0cmFuc2Zvcm0iIHR5cGU9InJvdGF0ZSIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiIGR1cj0iMC43MnMiIGtleVRpbWVzPSIwOzEiIHZhbHVlcz0iMCA1MCA1MDszNjAgNTAgNTAiPjwvYW5pbWF0ZVRyYW5zZm9ybT4KPC9jaXJjbGU+CjwhLS0gW2xkaW9dIGdlbmVyYXRlZCBieSBodHRwczovL2xvYWRpbmcuaW8vIC0tPjwvc3ZnPg==");
  background-size: 30px 30px;
}

.secondary-button.form-loading:after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' style='margin: auto; background: none; display: block; shape-rendering: auto;' width='200px' height='200px' viewBox='0 0 100 100' preserveAspectRatio='xMidYMid'%3E%3Ccircle cx='50' cy='50' r='32' stroke-width='8' stroke='%23000' stroke-dasharray='50.26548245743669 50.26548245743669' fill='none' stroke-linecap='round' transform='rotate(311.967 50 50)'%3E%3CanimateTransform attributeName='transform' type='rotate' repeatCount='indefinite' dur='0.72s' keyTimes='0;1' values='0 50 50;360 50 50'/%3E%3C/circle%3E%3C!-- %5Bldio%5D generated by https://loading.io/ --%3E%3C/svg%3E");
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 50000s ease-in-out 0s, color 5000s ease-in-out 0s;
}

/* Tab animations */

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(18px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@-moz-keyframes fadeInUp {
  0% {
    opacity: 0;
    -moz-transform: translateY(18px);
  }

  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}

@-o-keyframes fadeInUp {
  0% {
    opacity: 0;
    -o-transform: translateY(18px);
  }

  100% {
    opacity: 1;
    -o-transform: translateY(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.animated {
  -webkit-animation-duration: 0.8s;
  -moz-animation-duration: 0.8s;
  -o-animation-duration: 0.8s;
  animation-duration: 0.8s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  position: relative;
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  -moz-animation-name: fadeInUp;
  -o-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.ReactModal__Overlay {
  opacity: 0;
  transform: translateY(20px);
  transition: all 800ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transform: translateY(0px);
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transform: translateY(20px);
}

.axao__fonts-colored {
  text-transform: capitalize;
  background: linear-gradient(90.68deg, #caaad2 9.13%, #a3c9ee 66.96%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

/************** Hero section ***************/
/******************************************/

.main-page {
  width: 100%;
  margin: 0 auto;
  overflow-x: hidden;
}
@media only screen and (max-width: 1400px) {
  .main-page {
    width: 85%;
    margin: 0 auto;
  }
}
.herosection-home {
  width: 1011px;
  height: 857px;
  margin: 0 auto;
  margin-top: 30px;
  transition: transform 0.6s ease;
  border-radius: 20px;
  position: relative;
  max-width: 100%;
  cursor: pointer;
}
.herosection-home .herosection-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 20px;
}
.herosection-home p {
  position: absolute;
  bottom: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 94px;
  font-weight: 700;
  letter-spacing: -4.7px;
  text-transform: capitalize;
}

.herosection-home-caption {
  position: absolute;
  bottom: 83px;
  left: 82px;
}

.herosection-home-caption h3 {
  color: #FFF;
  font-size: 42.654px;
  font-weight: 300;
  line-height: normal;
  letter-spacing: -0.853px;
  margin-top: 26px;
}
.herosection-home-caption h6 {
  color: #FFF;
  font-size: 27.374px;
  font-weight: 300;
  line-height: normal;
  letter-spacing: -0.547px;
  margin-top: 26px;
  max-width: 555.766px;
}

.herosection-home-caption span {
  color: #0F71E2;
}
@media not all and (min-width: 640px) {
  .sm-hidden {
      display: none;
  }
}
@media only screen and (max-width: 1024px) {
  .herosection-home {
    max-width: 90%;
    height: 700px;
  }
  .herosection-home p {
    font-size: 70px;
    bottom: 50px;
  }
}

@media only screen and (max-width: 900px) {
  .herosection-home {
    max-width: 90%;
    height: 550px;
  }
  .herosection-home p {
    font-size: 38px;
    bottom: 35px;
  }
  .herosection-home .herosection-image {
    object-fit: cover;

    border-radius: 20px;
  }

  .herosection-home-caption {
    bottom: 42.21px;
    left: 40px;
  }
  
  .herosection-home-caption img {
    width: 70px;
    height: 70px;
  }

  .herosection-home-caption h3 {
    font-size: 32px;
    letter-spacing: -0.402px;
    margin-top: 18px;
  }
  .herosection-home-caption h6 {
    font-size: 20px;
    letter-spacing: -0.258px;
    margin-top: 18px;
    max-width: 400px;
  }
}
@media only screen and (max-width: 600px) {
  .herosection-home {
    max-width: 100%;
    height: 518px;
  }


  .herosection-home-caption {
    bottom: 42.21px;
    left: 40px;
  }
  
  .herosection-home-caption img {
    width: 49.272px;
    height: 49.272px;
  }

  .herosection-home-caption h3 {
    font-size: 20.108px;
    letter-spacing: -0.402px;
    margin-top: 12.26px;
  }
  .herosection-home-caption h6 {
    font-size: 12px;
    letter-spacing: -0.258px;
    margin-top: 12.26px;
    max-width: 262px;
  }
  
}
@font-face {
    font-family: 'Mont';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url('../fonts/mont/Mont-Thin.otf')
      format('truetype');
  }
  
  
  @font-face {
    font-family: 'Mont';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url('../fonts/mont/Mont-ExtraLight.otf')
      format('truetype');
  }
  
  @font-face {
    font-family: 'Mont';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/mont/Mont-Light.otf')
      format('truetype');
  }
  
  @font-face {
    font-family: 'Mont';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('../fonts/mont/Mont-Regular.otf')
      format('truetype');
  }
  
  @font-face {
    font-family: 'Mont';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('../fonts/mont/Mont-SemiBold.otf')
      format('truetype');
  }
  
  @font-face {
    font-family: 'Mont';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('../fonts/mont/Mont-SemiBold.otf')
      format('truetype');
  }
  

  @font-face {
    font-family: 'Mont';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('../fonts/mont/Mont-Bold.otf')
      format('truetype');
  }
  
  @font-face {
    font-family: 'Mont';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url('../fonts/mont/Mont-Black.otf')
      format('truetype');
  }
  
  @font-face {
    font-family: 'Mont';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url('../fonts/mont/Mont-Black.otf')
      format('truetype');
  }
  
  .font-mont {
    font-family: 'Mont', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
  }
  


  @font-face {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url('../fonts/helvetica/helvetica-light.ttf')
      format('truetype');
  }
  
  
  @font-face {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url('../fonts/helvetica/helvetica-light.ttf')
      format('truetype');
  }
  
  @font-face {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/helvetica/helvetica-light.ttf')
      format('truetype');
  }
  
  @font-face {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('../fonts/helvetica/Helvetica.ttf')
      format('truetype');
  }
  
  @font-face {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('../fonts/helvetica/Helvetica.ttf')
      format('truetype');
  }
  
  @font-face {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('../fonts/helvetica/Helvetica-Bold.ttf')
      format('truetype');
  }
  

  @font-face {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('../fonts/helvetica/Helvetica-Bold.ttf')
      format('truetype');
  }
  
  @font-face {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url('../fonts/helvetica/Helvetica-Bold.ttf')
      format('truetype');
  }
  
  @font-face {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url('../fonts/helvetica/Helvetica-Bold.ttf')
      format('truetype');
  }

  @font-face {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url('../fonts/helveticaNueue/HelveticaNeueUltraLight100.otf')
      format('truetype');
  }

  @font-face {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url('../fonts/helveticaNueue/HelveticaNeueThin200.otf')
      format('truetype');
  }

  @font-face {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('../fonts/helveticaNueue/HelveticaNeueLight300.otf')
      format('truetype');
  }

  @font-face {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('../fonts/helveticaNueue/HelveticaNeueRoman400.otf')
      format('truetype');
  }

  @font-face {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 500; 
    font-display: swap;
    src: url('../fonts/helveticaNueue/HelveticaNeueMedium500.otf')
      format('truetype');
  }

  @font-face {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('../fonts/helveticaNueue/HelveticaNeueBold700.otf')
      format('truetype');
  }

  @font-face {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('../fonts/helveticaNueue/HelveticaNeueBold700.otf')
      format('truetype');
  }

  @font-face {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url('../fonts/helveticaNueue/HelveticaNeueHeavy800.otf')
      format('truetype');
  }

  @font-face {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url('../fonts/helveticaNueue/HelveticaNeueBlack900.otf')
      format('truetype');
  }
  
  .font-helvetica {
    font-family: 'Helvetica', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
  }
  
  .font-helveticaNeue{
    font-family: 'Helvetica Neue', sans-serif!important;
  }
/*********************************************
--- Responsive-design
--- Store Header
  --- Hero-section
---- Store products

--- Media query breakdown
  --- @media only screen and (max-width: 375px)
  --- @media only screen and (max-width: 767px)
  --- @media only screen and (max-width: 1400px)
**************************************************/

.responsive-design {
  width: 100%;
  max-width: 1270px;
  margin: 0 auto;
}

/************** Header ****************/
/**************************************/
body {
  overflow-x: hidden;
}
.store-header {
  margin-top: 20px;
  position: relative;
  width: 100%;
  margin: 0 auto;
}
@media only screen and (max-width: 1400px) {
  .store-header {
    width: 100%;
  }
}
.store__hero-section,
.store__heading {
  width: 100%;
  max-width: 1270px;
}
.store__hero-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
}
.store__hero-section img {
  object-fit: cover;
}
@media only screen and (max-width: 1400px) {
  .store__hero-section img {
    max-width: 100%;
    border-radius: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .store__hero-section img {
    max-width: 100%;
    height: 300px;
  }
}

/************** Store ****************/
/*************************************/
.store-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100vw !important;
  max-width: 100vw !important;
  margin: 0 auto;
  overflow-x: scroll;
  scroll-behavior: smooth;
}
@media only screen and (min-width: 1500px) {
  .store-container {
    width: 1270px !important;
    max-width: 100% !important;
  }
}
@media only screen and (max-width: 1400px) {
  .store-container {
    width: 100% !important;
    max-width: 100% !important;
    /* overflow-x: initial !important; */
  }
}
@media only screen and (max-width: 767px) {
  .store-container {
    align-items: center;
    width: 100%;
  }
}
.store__heading {
  font-size: 32px;
  margin-top: 80px;
  /* margin: 40px auto 23px; */
  color: white;
  /* background: linear-gradient(91deg, #caaad2 9.13%, #a3c9ee 9.13%); */
  /* background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  font-weight: 300;
}
@media only screen and (max-width: 1400px) {
  .store__heading {
    display: flex;
    justify-content: flex-start;
  }
}
@media only screen and (max-width: 767px) {
  .store__heading {
    font-size: 30px;
    margin-left: 0;
  }
}
/**** Store items ****/
.store-items {
  display: grid;
  width: 110%;
  align-items: center;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  overflow-x: scroll;
  margin: 0 auto;
  /* margin-right: 50px;
  margin-left: 80px; */
}
.store-items--landingpage {
  margin-left: 60px;
}
@media only screen and (min-width: 1500px) {
  .store-items {
    width: 100%;
    gap: 1.3rem;
    margin-left: 0;
    margin-right: 0;
  }
  .store-items--landingpage {
    margin-left: 0;
  }
}
@media only screen and (max-width: 1400px) {
  .store-items {
    width: 100%;
    /* overflow-x: initial; */
    margin-left: 0;
    margin-right: 0;
  }
  .store-items--landingpage {
    margin-left: 0;
  }
}
/* please be careful with this line, it can destroy. i've seen shege because of this 2-3 lines for month */
::-webkit-scrollbar {
  display: none;
}

.buds--store-items {
  margin: 10px 0;
}
@media only screen and (max-width: 1200px) {
  .store-items {
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
  }
}

@media only screen and (max-width: 767px) {
  .store-items {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 400px) {
  .store-items {
    grid-template-columns: repeat(2, 1fr);
    gap: 0.76rem;
  }
}
.store__item {
  background: #101010;
  width: 100%;
  max-width: 350px;
  height: auto;
  /* padding: 10px; */
  border-radius: 4px;
  position: relative;
  border: 0.1px solid transparent;
  transition: border-color 0.5s ease;
  cursor: pointer;
}
.store-me:hover img {
  @apply md:opacity-100;
}
.store-me:hover .price {
  @apply md:ml-10;
}
.price {
  @apply text-[#D7D7D7];
}
@media only screen and (max-width: 767px) {
  .store__item {
    max-width: 250px;
  }
}
@media only screen and (max-width: 400px) {
  .store__item {
    max-width: 400px;
  }
}
.store-new {
  background: #8c8c8c;
  font-size: 14px;
  color: black;
  padding: 6px 8px;
  border-radius: 2px;
  position: absolute;
  top: 20px;
  left: 20px;
  font-weight: 400;
}
@media only screen and (max-width: 900px) {
  .store-new {
    font-size: 10px;
    padding: 4px 6px;
    border-radius: 2px;
    top: 16px;
    left: 16px;
  }
}
.store__item:hover {
  border-color: rgba(255, 255, 255, 0.418);
}
.store__item-savelater-container {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
@media only screen and (max-width: 767px) {
  .store__item-savelater-container {
    right: 18px;
    top: 18px;
    width: 25px;
    height: 25px;
  }
}
.store__item-savelater-container:hover {
  background-color: rgba(255, 255, 255, 0.14);
}
.store__item__savelater-icon {
  width: 14px;
  height: 14px;
}
@media only screen and (max-width: 767px) {
  .store__item__savelater-icon {
    width: 11px;
    height: 11px;
  }
}
.store__item-image {
  width: 100%;
  border-radius: 4px;
  object-fit: cover;
}
.store__item-name,
.store__item-price {
  display: block;
  font-weight: 300;
}
.store__item-name {
  margin-top: 20px;
  font-size: 20px;
  color: #d7d7d7;
}
@media only screen and (max-width: 767px) {
  .store__item-name {
    font-size: 18px;
  }
}
.store__item-price {
  margin: 30px 0 20px;
  font-size: 23px;
  color: #dbdbdb;
}
@media only screen and (max-width: 767px) {
  .store__item-price {
    font-size: 22px;
    margin: 30px 0 15px;
  }
}
/* For WebKit browsers (Chrome, Safari) */
.scrollable-container {
  /* Ensure the scrollbar is always visible */
  display: none;
  transition: all 0.4s ease-in-out;
  /* Thumb and Track colors */
}

.likedHeart {
  animation-name: like;
  animation-duration: 0.45s;
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  animation-fill-mode: forwards;
}

@keyframes like {
  from {
    font-size: 1.125rem; /* Tailwind text-lg: 18px */
    line-height: 1.75rem; /* Tailwind leading-7: 28px */
  }
  to {
    font-size: 1rem; /* Tailwind text-base: 16px */
    line-height: 1.5rem; /* Tailwind leading-6: 24px */
  }
}

.product__container:last-child .heart {
  @apply z-20;
}

/* .scrollable-container::-webkit-scrollbar {
  height: 4px;
  background-color: transparent;

}

.scrollable-container::-webkit-scrollbar-button {
  display: none;
}
.scrollable-container::-webkit-scrollbar-thumb {
  display: none;
}
.scrollable-container::-webkit-scrollbar {
  width: 8px!important;
} */
/* .scrollable-container:hover::-webkit-scrollbar-thumb {
  display: block;
  background: rgba(255, 255, 255, 0.14);
  opacity: 0.6;
  height: 1px!important;
  border-radius: 12px;
} */
/* @media (width >= 1024px) {
.store__btn {
  display: none !important;
}
} */
/* @media (width >= 1024px) {
  .store__container:hover .store__btn {
    display: block !important;
  }
} */

.animate-slide-left {
  animation: slideLeft 1.2s ease-in-out forwards;
}

.animate-slide-right {
  animation: slideLeft 1.2s ease-in-out forwards;
}

@keyframes slideLeft {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
.custom-pagination .MuiPagination-root {
  color: #ffffff; /* Set custom text color for the root */
}

.custom-pagination .MuiPagination-ul {
  gap: 8px; /* Adjust gap between items if needed */
}

.custom-pagination .MuiPaginationItem-root {
  color: #87b8f0; /* Custom text color for items */
}

.custom-pagination .MuiPaginationItem-root.Mui-selected {
  background-color: rgb(195, 191, 191); /* Background color for selected page */
  color: #000; /* Text color for selected page */
}

.custom-pagination .MuiPaginationItem-icon {
  color: white; /* Custom color for next/previous icons */
}

.custom-label .MuiFormControlLabel-label {
  @apply font-extralight tracking-tighter text-xl text-white/70 capitalize font-helvetical;
}

.custom-checked .Mui-checked {
  background: #000;
}

.slider .MuiSlider-track {
  background-color: rgba(0, 0, 0, 0.393);
  border-color: rgba(23, 22, 22, 0.393);
}
.slider .MuiSlider-rail {
  background: white;
}
.slider .MuiSlider-thumb {
  background: white;
  height:12px;
  width:12px
}
/* Swiper customization */
.swiper{
  padding-bottom: 3rem!important;
}
.swiper-pagination .swiper-pagination-bullet{
  background-color: rgba(255, 255, 255, 0.816);
  opacity: .4;
}
.swiper-pagination{
  bottom: -59px;
  margin-bottom: -16px;
}
.swiper-pagination .swiper-pagination-bullet-active{
  background-color: white;
  height: 8px;
  width: 1.4rem;
  border-radius: 5px;
  transition: all 750ms ease-in-out;
  opacity: 1;
}
/* End of Swiper customization */
/* Discount section */

.discount-container {
  margin-right: 40px;
  font-family: 'Helvetica Neue', sans-serif;
}
.discount-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 32px;
  text-transform: capitalize;
  color: #fff;
}
.discount-content h3 {
  font-size: 30px;
  font-weight: 400;
  letter-spacing: -2.033px;
  max-width: 300px;
}
.discount-content p {
  font-size: 13px;
  line-height: 27px;
  text-transform: none!important;
  max-width: 350px;
  opacity: 0.95;
}
form label {
  display: none;
}
form {
  width: 100%;
}
form input,
form button {
  padding: 21px 24px;
  width: 100%;
  background: transparent;
  font-weight: 300;
  font-family: inherit;
  border-radius: 10px;
  border: 1px solid #bfbfbf;
  outline: none;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.7);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition: all 0.3s ease;
}
form input:focus {
  border-color: #8da6c1;
}
form button {
  background: #0f71e2;
  border: none;
  font-size: 15px;
  margin-top: 27px;
  cursor: pointer;
  transition: all 0.3s ease;
}
form button:hover {
  background: #0d64c7;
}

::placeholder {
  color: #bfbfbf;
  font-family: inherit;
}

/********* Media Query *********/

@media only screen and (max-width: 767px) {
  .discount-content h3 {
    font-size: 25px;
    max-width: 250px;
  }
  .discount-content p {
    font-size: 14px;
    line-height: 33.1px;
    max-width: 300px;
  }
  form input,
  form button {
    padding: 18px 19px;
    width: 95%;
    font-size: 13px;
  }
  form button {
    font-size: 14px;
    margin-top: 20px;
  }
  .discount-exit p {
    font-size: 14px;
  }
}

/************************************************
--- StoreItem page
  --- StoreItem image
  --- StoreItem information
    --- name, description ...

--- Media query breakdown
  --- @media only screen and (max-width: 375px)
  --- @media only screen and (max-width: 900px)
  --- @media only screen and (max-width: 1400px)
******************************************************/
.responsive-design {
  width: 100%;
  max-width: 1310px;
  margin: 20px auto;
}
.svg{
  color: #941415;
}
.storeItem-page {
  background-color: #000;
  width: 100%;
  height: auto;
  max-width: 1310px;
  margin: 0 auto;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  font-weight: 100 !important;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  position: relative;
}
@media only screen and (max-width: 1400px) {
  .storeItem-page {
    width: 85%;
  }
}
.storeItem-page__inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 900px) {
  .storeItem-page__inner {
    flex-direction: column;
  }
}
/* storeItem image */
.storeItem-image__container {
  width: 45%;
  height: auto;
  max-height: 600px;
  position: relative;
}

@media only screen and (max-width: 900px) {
  .storeItem-image__container {
    width: 100%;
    height: 400px;
  }
}
@media only screen and (max-width: 500px) {
  .storeItem-image__container {
    height: auto;
  }
}
/* Color sliders */
.color-sliders {
  position: absolute;
  bottom: 48px;
  width: 100%;
}
.color-slider__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 85%;
  margin: 0 auto;
}
.color-sliders__arrows {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid white;
  background-color: transparent;
}
.color-sliders__arrows--right img {
  transform: rotate(180deg);
}
.color-sliders__arrows img {
  width: 25px;
  height: 25px;
  opacity: 0.6;
  cursor: pointer;
}
@media only screen and (max-width: 1024px) {
  .color-sliders__arrows {
    width: 35px;
    height: 35px;
  }
  .color-sliders__arrows img {
    width: 15px;
    height: 15px;
  }
}
@media only screen and (max-width: 500px) {
  .color-sliders {
    bottom: 30px;
  }
  .color-sliders__arrows {
    width: 30px;
    height: 30px;
  }
  .color-sliders__arrows img {
    width: 13px;
    height: 13px;
  }
}

.storeItem__color-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.slider__color {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: inline-block;
  margin: 0 7px;
  border: 2px solid transparent;
}
.slider__color--active {
  border-color: white;
}

.storeItem-image__bgImage {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
/* storeItem information */
.storeItem__info {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 50%;
  flex-direction: column;
  background-color:#000;
  color: #d7d7d7;
  padding-inline: 30px;
}
@media only screen and (max-width: 900px) {
  .storeItem__info {
    width: 100%;
    padding: 30px 25px;
  }
}
@media only screen and (max-width: 375px) {
  .storeItem__info {
    padding: 30px 20px;
  }
}
.storeItem__info-name {
  font-size: 44px;
  margin-bottom: 10px;
  font-weight: 100;
}
@media only screen and (max-width: 1400px) {
  .storeItem__info-name {
    font-size: 35px;
  }
}
@media only screen and (max-width: 375px) {
  .storeItem__info-name {
    font-size: 30px;
  }
}
.storeItem__info-description {
  font-size: 14px;
}
@media only screen and (max-width: 375px) {
  .storeItem__info-description {
    font-size: 12px;
  }
}
/****** product quantity ******/
.storeItem__quantity-container {
  width: 100%;
  height: 130px;
  border-radius: 4px;
  background-color: black;
  margin: 20px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
@media only screen and (max-width: 767px) {
  .storeItem__quantity-container {
    padding: 0 15px;
  }
}

@media only screen and (max-width: 400px) {
  .storeItem__quantity-container {
    height: 100%;
    padding: 30px 20px;
    gap: 20px;
    align-items: flex-start;
    flex-direction: column;
  }
}
/* Store color container */
.storeItem__color-container {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
}
@media only screen and (max-width: 767px) {
  .storeItem__color-container {
    padding: 0;
    margin-right: 25px;
  }
}
.storeItem__color-heading {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  color: #ffffff;
  margin-bottom: 14px;
}
@media only screen and (max-width: 767px) {
  .storeItem__color-heading {
    font-size: 13px;
  }
}
.storeItem__color-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 13px;
}
@media only screen and (max-width: 767px) {
  .storeItem__color-wrapper {
    gap: 10px;
  }
}
.storeItem__color {
  display: block;
  width: 43px;
  height: 43px;
  border-radius: 50%;
}
@media only screen and (max-width: 767px) {
  .storeItem__color {
    width: 33px;
    height: 33px;
  }
}
.storeItem__color--green {
  background-color: #7cb93e;
}
.storeItem__color--blue {
  background-color: #33abc6;
}
.storeItem__color--red {
  background: #941415;
}
.storeItem__quantity {
  display: flex;
  flex-direction: column;
  margin-left: 0px;
}
.storeItem__quantity-heading {
  color: #ffffff;
  font-size: 14px;
  margin-bottom: 14px;
}
@media only screen and (max-width: 767px) {
  .storeItem__quantity-heading {
    font-size: 13px;
  }
  .storeItem__quantity {
    margin-left: 0;
  }
}
.storeItem__quantity-amount {
  border: 0.6px solid white;
  padding: 12px;
  border-radius: 4px;
  width: 75px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2px;
}
@media only screen and (max-width: 767px) {
  .storeItem__quantity-amount {
    padding: 10px;
  }
}
.storeItem__quantity-amount input {
  font-size: 18px;
  border: none;
  width: 50%;
  /* margin-right: 3px; */
  /* text-align: center; */
  background-color: transparent;
  color: white;
  -moz-appearance: textfield;
  appearance: textfield;
}
.storeItem__quantity-amount input::-webkit-outer-spin-button,
.storeItem__quantity-amount input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .storeItem__quantity-amount {
    padding: 10px;
  }
}
.storeItem__quantity-img__container {
  display: flex;
  justify-content: flex-end;
  width: 50%;
}
.storeItem__quantity-img__container img {
  cursor: pointer;
}
.storeItem__quantity-img__containerimg:hover {
  opacity: 0.8;
}
/********* about ***********/
.storeItem__product-about {
  font-size: 24px;
  margin-bottom: 25px;
  font-weight: 200;
  color: #fff;
}
@media only screen and (max-width: 767px) {
  .storeItem__product-about {
    font-size: 20px;
  }
}
.storeItem__product-info {
  color: #ffffff;
  line-height: 150%;
  /* font-size: 16px; */
  @apply font-extralight tracking-wide text-sm text-white;
  /* text-align: justify; */
  position: relative;
  top: -10px;
}
.storeItem__product-info span {
}
@media only screen and (max-width: 767px) {
  .storeItem__product-info {
    font-size: 14px;
    line-height: 150%;
  }
}
/*********** cart ***********/
.storeItem__cart-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
@media only screen and (max-width: 375px) {
  .storeItem__cart-container {
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 30px;
  }
}
.storeItem__price {
  color: #dbdbdb;
  font-size: 34px;
}
@media only screen and (max-width: 767px) {
  .storeItem__price {
    width: 100%;
    font-size: 28px;
  }
}
.storeItem__cart-wrapper {
  width: auto;
}
@media only screen and (max-width: 375px) {
  .storeItem__cart-wrapper {
    width: 100%;
  }
}
.storeItem__cart {
  width: 130px;
  border: none;
  outline: none;
  background-color: #0f71e2;
  height: 48px;
  font-family: inherit;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  border-radius: 4px;
  cursor: pointer;
}
.storeItem__cart:disabled{
  cursor: not-allowed;
}
@media only screen and (max-width: 375px) {
  .storeItem__cart {
    width: 100%;
    margin-top: 20px;
    height: 48px;
  }
}
.storeItem__cart:hover :not(:disabled) {
  background-color: #065abb;
}

.likedHeart {
  animation-name: like;
  animation-duration: 2s;
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  animation-fill-mode: forwards;
}

@keyframes like {
  from {
    font-size: 1.125rem; /* Tailwind text-lg: 18px */
    line-height: 1.75rem; /* Tailwind leading-7: 28px */
  }
  to {
    font-size: 1rem; /* Tailwind text-base: 16px */
    line-height: 1.5rem; /* Tailwind leading-6: 24px */
  }
}

/************************************
--- Dropdown
**************************************/

.overflow-hidden {
  overflow-y: hidden;
  overflow-x: hidden;
}
.nav-dropdown {
  background: var(
    --linear-grey,
    linear-gradient(180deg, #080808 0%, #0f0e0e 100%)
  );
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: white;
  padding-bottom: 80px;
  display: none;
  position: absolute;
  z-index: 99;
  height: 550px;
  right: 0;
  left: 0;
  top: 53px;
  transition: all 0.5s ease-in-out;
}
.close-dropdown.product-tab-dropdown:hover .nav-dropdown {
  display: none;
}
.close-dropdown.product-tab-dropdown:hover .product-dropdown {
  transform: rotate(180deg);
}
.product-dropdown {
  transition: transform 0.5s ease-in-out;
}
.product-tab-dropdown:hover .nav-dropdown {
  display: block;
}
.product-tab-dropdown:hover .product-dropdown {
  transform: rotate(180deg);
}

/* Dropdown styles */
.product-tab-dropdown:hover .bottom-blur {
  display: block;
}
.product-tab-dropdown:hover .bottom-blur:hover {
  display: none !important;
}
.bottom-blur:hover .product-dropdown {
  display: none !important;
}
/* BG exra */
.black-color {
  background: black;
  width: 100%;
  height: 50px;
}

/* BG exra ends */
.nav-dropdown__texts {
  padding: 20px 0px;
  margin: 0 70px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 50px;
  text-transform: capitalize;
}
/* nav content style */
.nav-dropdown__allProducts {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 24px;
  margin-top: 30px;
}
.nav-dropdown__heading {
  color: #7e7e7e;
  font-size: 14px;
  font-weight: 400;
}

.nav-dropdown__tab {
  color: #ededed;
  font-size: 24px;
  font-weight: 400;
  transition: all 0.6s ease;
}
.product-tab-dropdown:hover .drop__me{
transform: translateY(0)!important;
}
.nav-dropdown__tab:hover {
  text-decoration: underline white 1px;
}
/* Media Query */
@media only screen and (max-width: 1200px) {
  .nav-dropdown__texts {
    margin: 0 50px;
    gap: 90px;
  }
  .nav-dropdown__allProducts {
    gap: 23px;
  }
  .nav-dropdown__heading {
    font-size: 12px;
  }
  .nav-dropdown__tab {
    font-size: 20px;
    width: 100%;
  }
  .nav-dropdown {
    height: 450px;
  }
}
@media only screen and (max-width: 767px) {
  .nav-dropdown {
    display: none;
  }
}
.payment__header{
  animation: payment 450ms forwards ease-in-out;
}
@keyframes payment {
  0%{
    width:0%;
  }
  100%{
    width: 50%;
  }
}